/** @format */

import styled from "styled-components";

export const StyledNav = styled.nav`
  display: flex;
  gap: clamp(30px, 1.863vw, 50px);

  a {
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    transition: color 250ms linear;

    &:hover {
      color: #0bc76d;
    }

    &:focus-visible {
      outline-width: 1px;
      outline-style: solid;
      outline-color: #0bc76d;
      outline-offset: 4px;
    }
  }

  @media only screen and (max-width: 992px) {
    display: ${({ isopen }) => (isopen ? "flex" : "none")};
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 50px;

    a {
      font-size: 26px;
      color: #000;
    }
  }
`;
