/** @format */

import styled from "styled-components";

export const StyledLogo = styled.div`
  max-width: 273px;

  @media only screen and (max-width: 992px) {
    max-width: 160px;
    position: ${({ isheader }) => (isheader ? "absolute" : "relative")};
    top: ${({ isheader }) => (isheader ? "10px" : "auto")};
    left: ${({ isheader }) => (isheader ? "20px" : "auto")};
  }
`;
