/** @format */

import React from "react";
import { Link } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";

import Logo from "../../atoms/Logo/Logo";
import ParseText from "../../atoms/ParseText/ParseText";

import {
  StyledFooter,
  StyledLeftWrapper,
  StyledLinks,
  StyledContent,
  StyledCoopyrightWrapper,
  StyledRightWrapper,
  StyledCertWrapper,
} from "./StyledFooter";
import { StyledText } from "../../atoms/StyledText/StyledText";

import certImg from "../../../images/znak.png";

const Footer = () => {
  const {
    datoCmsGlobalneUstawieniaa: {
      coopyrightText,
      nip,
      napisPodLogiem,
      regon,
      adresPierwszaLinia,
      adresDrugaLinia,
    },
  } = useStaticQuery(graphql`
    query footerQuery {
      datoCmsGlobalneUstawieniaa {
        coopyrightText
        nip
        napisPodLogiem
        regon
        adresPierwszaLinia
        adresDrugaLinia
      }
    }
  `);

  return (
    <StyledFooter>
      <StyledContent>
        <StyledLeftWrapper>
          <Logo />
          <StyledCertWrapper>
            <img src={certImg} alt="certyfikat" />
          </StyledCertWrapper>
          <StyledText
            hasdeclaredfontsize="18px"
            hasdeclaredfontcolor="#fff"
            hasdeclaredmargin="30px 0 14px 0"
          >
            {napisPodLogiem}
          </StyledText>
          <StyledText
            hasdeclaredfontsize="18px"
            hasdeclaredfontcolor="#fff"
            hasdeclaredmargin="0 0 14px 0"
          >
            {adresPierwszaLinia}
          </StyledText>
          <StyledText
            hasdeclaredfontsize="18px"
            hasdeclaredfontcolor="#fff"
            hasdeclaredmargin="0 0 14px 0"
          >
            {adresDrugaLinia}
          </StyledText>
          <StyledText
            hasdeclaredfontsize="18px"
            hasdeclaredfontcolor="#fff"
            hasdeclaredmargin="0 0 14px 0"
          >
            {nip}
          </StyledText>
          <StyledText
            hasdeclaredfontsize="18px"
            hasdeclaredfontcolor="#fff"
            hasdeclaredmargin="0 0 14px 0"
          >
            {regon}
          </StyledText>
        </StyledLeftWrapper>
        <StyledRightWrapper>
          <StyledLinks>
            <StyledText
              hasdeclaredfontsize="20px"
              hasdeclaredfontcolor="#fff"
              hasdeclaredmargin="0 0 20px 0"
            >
              Mapa strony:
            </StyledText>
            <Link to="/o-nas">O NAS</Link>
            <Link to="/oferta">OFERTA</Link>
            <Link to="/realizacje">REALIZACJE</Link>
            <Link to="/kontakt">KONTAKT</Link>
          </StyledLinks>
        </StyledRightWrapper>
      </StyledContent>
      <StyledCoopyrightWrapper>
        <ParseText text={coopyrightText} /> {new Date().getFullYear()}
      </StyledCoopyrightWrapper>
    </StyledFooter>
  );
};

export default Footer;
