/** @format */

import { createGlobalStyle } from "styled-components";
import "./fonts.css";
import "./normalize.css";

const GlobalStyle = createGlobalStyle`
    html{
        overflow-x: hidden;
        scroll-behavior: smooth;
    } 

    body {
      width: 100%;
    }
    
    *{
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      font-variant-numeric: oldstyle-nums;
      font-family: 'Poppins';
      scroll-padding-top: 40px;
    }
    
    *:focus-visible {
      outline-width: 1px;
      outline-style: solid;
      outline-color: #da9610;
      outline-offset: 4px;
    }

    *:focus {
      outline: none
    }

    p {
      font-variant-numeric: oldstyle-nums;
    }

    *::before,
    *::after{
        box-sizing: inherit;
        margin: 0;
        padding: 0;
    }
`;

export default GlobalStyle;
