/** @format */

import React from "react";
import { Link } from "gatsby";

import { StyledNav } from "./StyledNav";

const Nav = ({ isOpen, openMenu }) => {
  return (
    <StyledNav isopen={isOpen}>
      <Link to="/o-nas" onClick={openMenu}>
        O nas
      </Link>
      <Link to="/oferta" onClick={openMenu}>
        Oferta
      </Link>
      <Link to="/realizacje" onClick={openMenu}>
        Realizacje
      </Link>
      <Link to="/#referencje" onClick={openMenu}>
        Referencje
      </Link>
      <Link to="/#certyfikaty" onClick={openMenu}>
        Certyfikaty
      </Link>
      <Link to="/kontakt" onClick={openMenu}>
        Kontakt
      </Link>
    </StyledNav>
  );
};

export default Nav;
