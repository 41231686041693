/** @format */

import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import Image from "../Image/Image";

import { StyledLogo } from "./StyledLogo";

const Logo = ({ isOpen, isHeader }) => {
  const {
    datoCmsGlobalneUstawieniaa: { logo },
  } = useStaticQuery(graphql`
    query logoQuery {
      datoCmsGlobalneUstawieniaa {
        logo {
          alt
          title
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <Link to="/" aria-label="strona główna">
      <StyledLogo isopen={isOpen} isheader={isHeader}>
        <Image image={logo} />
      </StyledLogo>
    </Link>
  );
};

export default Logo;
