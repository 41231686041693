/** @format */

import React, { useState } from "react";

import Logo from "../../atoms/Logo/Logo";
import Nav from "../../molecules/Nav/Nav";
import HamburgerButton from "../../molecules/HamburgerButton/HamburgerButton";

import { StyledHeader } from "./StyledHeader";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledHeader isopen={isOpen}>
      <Logo isOpen={isOpen} isHeader={true} />
      <Nav isOpen={isOpen} openMenu={() => setIsOpen(!isOpen)} />
      <HamburgerButton openMenu={() => setIsOpen(!isOpen)} isOpen={isOpen} />
    </StyledHeader>
  );
};

export default Header;
