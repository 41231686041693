/** @format */

import React from "react";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Image = ({
  className = "",
  image,
  objectFit,
  loading = "lazy",
  dataIndex,
}) => {
  return image ? (
    <GatsbyImage
      className={className}
      image={getImage(image.gatsbyImageData)}
      alt={image.alt ? image.alt : ""}
      title={image?.title}
      objectFit={objectFit}
      loading={loading}
      data-index={dataIndex}
    />
  ) : null;
};

export default Image;
