/** @format */

import styled from "styled-components";

export const StyledHeader = styled.header`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 10px clamp(22px, 3.208vw, 60px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  min-height: 60px;

  @media only screen and (max-width: 992px) {
    position: ${({ isopen }) => (isopen ? "fixed" : "relative")};
    height: ${({ isopen }) => (isopen ? "100%" : "initial")};
    z-index: 10;
    min-height: 60px;
    background-color: #fff;
  }
`;
